import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Ourstory from '../views/OurStory.vue'
import WriteStory from '../views/WriteStory.vue'
import WriteCard from '../views/WriteCard.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Ourstory',
    name: 'Ourstory',
    component: Ourstory
  },
  {
    path: '/WriteStory',
    name: 'WriteStory',
    component: WriteStory
  },
  {
    path: '/WriteCard',
    name: 'WriteCard',
    component: WriteCard
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
